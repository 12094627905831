/* eslint-disable */

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/auth/login.vue";
import Callback from "../views/auth/callback.vue";
import loader from "../mixins/loader";
import store from "@/store/index.js";
import axios from "axios";
import CreateOrg from "@/views/supervisoryorg/CreateOrg.vue";
import ChangeOrg from "@/views/supervisoryorg/ChangeOrg.vue";
import DeleteOrg from "@/views/supervisoryorg/DeleteOrg.vue";
import Logout from "../views/auth/logout.vue";
import displayError from "../mixins/errorHandler";

import { authProvider } from "@/plugins/auth";

var jwt = require("jsonwebtoken");

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },
  {
    path: "/auth/callback",
    name: "callback",
    component: Callback,
    meta: {
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/",
    name: "Login",
    component: Login,
    meta: {
      hideFooter: true,
      hideHeader: true,
    },
  },
  {
    path: "/create/:person",
    name: "create",
    component: CreateOrg
  },
  {
    path: "/change/:person",
    name: "change",
    component: ChangeOrg,
  },
  {
    path: "/delete/:person",
    name: "delete",
    component: DeleteOrg,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    meta: {
      hideFooter: true,
      hideHeader: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(function (to, from, next) {
  //User already logged in 
  if (!sessionStorage.getItem("userData")) {
    loader.showLoading();
    getAccessTokenOpenID();
    loader.hideLoading();
    next();
  } else {
    //Log User in and call API to get supervisory org information 
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    checkUser(userData.token, userData.uid);
    next();
  }
  if (
    sessionStorage.getItem("userData") &&
    JSON.parse(sessionStorage.getItem("userData")).expiryTime
  ) {
    var expiryTimeleft = Math.round(
      (JSON.parse(sessionStorage.getItem("userData")).expiryTime -
        Math.round(Date.now() / 1000)) /
        60
    );
      //Sign user out if token has expired 
    if (expiryTimeleft <= 0) {
      authProvider.signout();
      sessionStorage.setItem("isLoggedOut", true);
    }
  }
});

//Function to get Access Token from the Auth Provider (AD) and get back user data 
async function getAccessTokenOpenID() {
  const { accessToken } = await authProvider.getGatewayAccessToken();
  const graphToken = (await authProvider.getAccessToken()).accessToken;
  var decoded = jwt.decode(accessToken);
  var expiryTime = decoded.exp;
  const userData = {
    name: decoded.name,
    uid: decoded.employee_id,
    email: decoded.preferred_username,
    token: accessToken,
    graphToken: graphToken,
    expiryTime: expiryTime,
  };
  sessionStorage.setItem("userData", JSON.stringify(userData));
  sessionStorage.setItem("isLoggedOut", false);
  store.dispatch("addUserData", userData);
  await checkUser(userData.token, userData.uid);
}

//Check if user is a supervisor or not and get supervisory structure 
async function checkUser(token, uid) {
  loader.showLoading();
  //Get Current Date
  let objectDate = new Date();
  let day = objectDate.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  let month = objectDate.getMonth();
  month = month + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let year = objectDate.getFullYear();

  let result = await httpGetuserAccess(`/supervisoryorgs/${uid}/${year}-${month}-${day}`, true, token); //Call to get supervisory orgs 
  // let result = await httpGetuserAccess(`/supervisoryorgs/${uid}/` , true, token)
  if (result.data.Report_Entry.length === 0) { //User not a Supervisor 
    // console.log("Not Supervisor ");
    sessionStorage.setItem("isSupervisor", false);
    store.dispatch("addSupervisor", false);
    loader.hideLoading();
  } else { //User is supervisor and get their supervisory org 
    // console.log("Supervisor");
    // console.log(result.data.Report_Entry);
    sessionStorage.setItem("isSupervisor", true);
    store.dispatch("addSupervisor", true);
    let supervisoryOrg = result.data.Report_Entry;
    let treeData = list_to_tree(supervisoryOrg);
    // console.log("tree data:::", treeData);
    store.dispatch("addSupervisoryOrg", treeData[0]);
    loader.hideLoading();
    // console.log("Loader hide loading after dispatching")
  }
}
//Function to take the API result and transform into something the Org Chart API can use 
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].Org_Code] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (i == list.length -1) { //edge case where we add a class for last node in org chart 
      node["last_node_class"] = "chart-row-lastchild"
    }
    if (i !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.Superior_Code]]["children"].push(node);
    } 
    if (list.length == 2 && i == 0) { //edge case where we add a class for when the supervisor only has one direct report 
      node["last_node_class"] = "chart-row-lastchild"
      roots.push(node)
    }else {
      roots.push(node);
    }
  }
  return roots;
}

//Actual Get Supervisor Org API Call with Axios
async function httpGetuserAccess(url, silent, token) {
  return axios({
    method: "get",
    maxBodyLength: Infinity,
    url: `${store.getters.getHostName}${url}`,
    responseType: "json",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((response) => {
      // console.log("Got data", response.data);
      if (response.data.status == 200) {
        return response.data;
      } else if (response.data.status == 2000) {
        displayError(response.data.msg);
      } else if (response.data.status == 2001) {
        return response.data;
      } else {
        displayError(response.data.msg);
      }
    })
    .catch((error) => {
      loader.hideLoading(silent == true ? silent : false)
      // console.log("exception", error);
      displayError(error);
    });
}

export default router;
