/* eslint-disable */
<template>
    
</template>

<script>
    import { authProvider } from "../../plugins/auth";
    export default {
        mounted() {
            authProvider.login()
        }
    }
</script>

<style>
</style>