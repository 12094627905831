<template>
  <div class="input-field col s4 supervisor-dropdown">
    <label :class="disabledLabel"
      >Manager/Position<i
        class="material-icons dp48 tooltipped tiny blue-text"
        data-position="right"
        :data-tooltip="instructions"
        >info</i
      ></label
    ><br /><br />
    <span :class="className" data-position="bottom" :data-tooltip="selectedManager">
      <select
        required
        :disabled="disableManager"
        v-model="answer[`${formFieldKey}`]"
        @change="validateField($event)"
        id="dropdown"
      >
        <option v-if="supOrgFunction == 'create'" value="" disabled selected>
          {{ placeholder }}
        </option>
        <option
          v-for="option in getDropdownData"
          :value="option.Position_ID"
          :key="option.Position_ID"
        >
          {{ option.Position }}
        </option>
      </select>
    </span>
    <div class="error" v-if="inlineError">
      <i class="material-icons dp48">error_outline</i>
      <span>{{ inlineError }}</span>
    </div>
    <div class="error" v-else-if="answer[`${formFieldKey}`]"></div>
    <div class="error" v-else-if="inlineErrorRequired">
      <i class="material-icons dp48">error_outline</i>
      <span>Please fill out the required field</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputDropdown",
  props: {
    optionData: {
      type: Array,
      default: () => [],
    },
    answer: {
      type: Object,
      default: () => ({}),
    },
    formFieldKey: {
      type: String,
      default: "",
    },
    keyValueData: {
      type: Object,
      default: () => ({}),
    },
    inlineErrorRequired: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    supOrgFunction: {
      type: String,
      default: "",
    },
    instructions: {
      type: String,
      default: "",
    },
    currentManager: {
      type: String,
      default: ""
    },
    disableManager :{
      type: Boolean,
      default: false
    },
    disabledLabel: {
      type: String,
      default: ""
    }

  },
  data() {
    return {
      inlineError: "",
      originalManager: "",
      selectedManager: null,
      className: ""
    };
  },
  created() {
    $(".tooltipped").tooltip();
    if (this.supOrgFunction == 'change') {
      this.className = "tooltipped"
      this.selectedManager = this.currentManager
    }
  },
  computed: {
    getDropdownData() {
      return this.optionData;
    },
  },
  mounted() {
    //Set original Manager to default
    this.originalManager = this.answer.positionId;
    $("select").formSelect();
    $(document).click(function(){
    $('li[id^="select-options"]').on('touchend', function (e) {
        e.stopPropagation();
    });
}); 
  },
  updated: function () {
    $("select").formSelect();
    $(".tooltipped").tooltip();
  },
  methods: {
    validateField(val) {
      var id = val.target.value
      var name = val.target.options[val.target.options.selectedIndex].text
      this.inlineError = "";
      this.selectedManager = name
      this.className = "tooltipped"
      //Validation for if the manager field is filled in
      if (!this.answer[`${this.formFieldKey}`]) {
        this.inlineError = "Please select a valid manager and position";
        this.$emit("validateSubmit", ["dropdown", false]);
      }
      //Validation for if the chosen person is already a manager or not
      else if (this.keyValueData[id] == "1") {
        if (this.supOrgFunction == "change" && id == this.originalManager) {
          //Exception for Change Org where the original manager can be selected again
          this.$emit("validateSubmit", ["dropdown", true]);
        } else {
          this.inlineError =
            "This person is already a manager. Please select a person who is not already a manager or remove this manager from the existing org they manage. ";
          this.$emit("validateSubmit", ["dropdown", false]);
        }
      } else {
        this.inlineError = "";
        this.$emit("validateSubmit", ["dropdown", true]);
      }
    },
  },
};
</script>

<style>
.supervisor-dropdown .dropdown-trigger {
  border: 1px solid #979797 !important;
  color: #424242 !important;
  border-radius: 5px !important;
}
.supervisor-dropdown .select-wrapper input.select-dropdown {
  display: block !important;
  width: 99% !important;
  border: 1px solid #979797 !important;
  border-radius: 5px !important;
  padding: 0px 8px;
  color: #424242 !important;
  font-size: 16px !important;
  font-weight: 300;
}

.supervisor-dropdown .dropdown-content {
  height: 100px;
  overflow-y: scroll;
}

.supervisor-dropdown .error {
  color: #d52b1e;
  text-align: left;
  margin: 0px 0 0 0px;
  float: left;
}
.supervisor-dropdown .error i {
  margin: -4px 5px 0px 0px;
  float: left;
}
.supervisor-dropdown .error span {
  margin: 0px 0px 0px 5px;
  display: flex;
  font-weight: 350;
}

.disabled {
  opacity: 0.5;
}

li.disabled{
  display: none;
}

</style>
