/* eslint-env jquery */
<template>
  <div class="input-field col s4 home-datepicker">
    <input
      id="supervisor-datepicker"
      :value="defaultDate"
      type="text"
      class="datepicker datepickercolumn date-placeholder"
      @change="onChangeFunc($event.target.value)"
    />
    <i id="date-icon" class="material-icons dp48 icon icon-calender">grid_on</i>
  </div>
</template>

<script>

export default {
  name: "DatePickerHome",
  props: {
    defaultDate: String
  }
  ,
  data() {
    return {
    };
  },
  created() {
  },
  updated() {},
  mounted() {
    this.initBindings();
    $(document).ready(function () {
      $("#supervisor-datepicker").click(function () {
        $(".datepicker").datepicker({
          format: "yyyy-mm-dd",
          defaultDate: new Date(),
          showClearBtn: true,
        });
      });
    });
  },
  methods: {
    //Emits the date picked back to the home screen
    onChangeFunc(value) {
      this.$emit("chooseDate", value)
    },
    initBindings() {
      $("#date-icon").click(function () {
        $("#supervisor-datepicker").click();
      });
    },
  },
};
</script>

<style scoped>
.datepickercolumn {
  width: 25% !important;
}
.icon-calender {
  cursor: pointer;
  float: right;
  margin: 11px 0px 0px 0px;
  color:#263f6a;
}
.datepicker .datepicker-calendar .datepicker-controls .select-month input {
  width: 90px !important;
}

</style>
