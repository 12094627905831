<template>
    <div>
      <h5>You have been signed out of the App.Please close the browser window and open the application again....</h5>
    </div>
  </template>
  
  <script>
  import { authProvider } from "../../plugins/auth";
  export default {
    mounted() {
      authProvider.signout();
    },
  };
  </script>
  
  <style></style>
  