/* eslint-disable */
<template>
    <div class="footer">
        <div class="">
            <div class="no-marigin">
                <div>
                    <div class="left-side">
                        <span>Copyright &copy; {{ new Date().getFullYear()  }} Lilly. All rights reserved.</span>
                    </div>
                    <div class="right-side">
                        <span>
                        Powered By
                        <img 
                            src="../assets/image/lillyRed.png"
                        >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style>

.left-side {
    float: left;
}

.right-side {
    float: right;
}

.footer {
    background-color: #ffffff;
    height: 60px;
    display: block !important;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    line-height: 62px;
    padding: 0px 25px;
  }
.footer img {
  height: 20px;
  vertical-align: -6px;
}
</style>