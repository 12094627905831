import { AuthProvider } from "@elilillyco/spa_auth"

const config = {
    clientId: process.env.VUE_APP_CLIENT_ID,
    openIdCallback: "/auth/callback",
    authority: "https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580",
    scopes: ["openid", "profile", "offline_access", "user.read", "email"],
    isGatewayScopeRequired: true,
    gatewayScopeType: 'production',
    logLevel: "INFO",
    cacheLocation: "sessionStorage"
};

export const authProvider = new AuthProvider(config)