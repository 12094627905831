<template>
  <div class="input-field col cost-center s4 supervisor-autocomplete">
    <label>{{ fieldName }}<i class="material-icons dp48 tooltipped tiny blue-text" data-position="right" :data-tooltip="instructions">info</i></label
    ><br /><br />
    <input
      type="text"
      class="supervisor-org lookup-field validate"
      :class="className"
      v-model="defaultVal[`${formFieldKey}`]"
      :placeholder="placeholder"
      id="input_text"
      @change="validateField($event);changeClear()"
    />
    <i class="material-icons" @click="clearErrorMsg" id="clear" :style="{opacity: filled ? 1 : 0}"
        >clear</i>
    <br><br><br>
    <div class="error" v-if="inlineError || validationNeeded">
      <i class="material-icons dp48">error_outline</i>
      <span v-if="inlineError">
        Please select a valid {{ this.fieldName }} or leave blank to default: &nbsp<a href="https://now.lilly.com/page/global-contact-hr" target="_blank">  Need Help?</a>
      </span>
      <span v-if="validationNeeded">
        Please select a valid {{ this.fieldName }}: &nbsp <a href="https://now.lilly.com/page/global-contact-hr" target="_blank"> Need Help?</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "autocomplete",
  props: {
    answer: Object,
    formFieldKey: String,
    optionData: Object,
    fieldName: String,
    className: String,
    placeholder: String,
    keyValueData: Object,
    instructions: String,
    defaultVal: Object
  },
  data() {
    return {
      inlineError: false,
      filled: true,
      validationNeeded: false
    }
  },
  methods: {
    validateField(event) {
      let vals = Object.values(this.keyValueData) //possible Autocomplete values 
      this.answer[`${this.formFieldKey}`] = this.keyValueData[event.target.value] //set final object to value connected to display value
      this.defaultVal[`${this.formFieldKey}`] = event.target.value //set field to display value
      this.validationNeeded = false
      this.inlineError = false
      //validation to assure field is filled out and otherwise default filled in 
      if ((!this.defaultVal[`${this.formFieldKey}`])) {
        this.inlineError = true
        this.$emit("validateSubmit", ["autocomplete",true])
      }
      //validation to assure typed in field exists in autocomplete options
      else if(!vals.includes(this.answer[`${this.formFieldKey}`])){
        this.validationNeeded = true
        this.$emit("validateSubmit", ["autocomplete", false])
      }
      else {
        this.$emit("validateSubmit", ["autocomplete", true])
      }

    },
    //Method to clear autcomplete field with button 
    clearErrorMsg() {
      this.validationNeeded = false
      this.defaultVal[`${this.formFieldKey}`] = "";
      this.filled = false
      this.inlineError = true
      this.$emit("validateSubmit", ["autocomplete",true])
    },
    //Method to change whether the clear button is shown or not 
    changeClear() {
      if(this.defaultVal[`${this.formFieldKey}`] != "") {
        this.filled = true
      }
      else {
        this.filled = false
      }
    }
  },  
  created() {
    //Makes sure the object takes the value of the default display value
    this.answer[`${this.formFieldKey}`] = this.keyValueData[this.defaultVal[`${this.formFieldKey}`]]
  },
  mounted() {
    let self = this;
    $("input." + this.className).autocomplete({
      data: this.optionData,
      minLength: 0,
    });
    $('.tooltipped').tooltip()
  },
};
</script>

<style>
.supervisor-autocomplete select {
  display: block;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 0px 8px;
  color: #424242;
  font-size: 16px;
  font-weight: 300;
}

.supervisor-autocomplete .col.s4 {
  margin-left: inherit;
  margin-right: 90px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.supervisor-autocomplete input {
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  color: #424242;
  margin: 5px 0px 0px 0px;
}
.supervisor-autocomplete label {
  font-size: 19px;
  font-weight: 400;
  color: #424242;
}
.supervisor-autocomplete .error {
  color: #d52b1e;
  text-align: left;
  margin: 0px 0 0 0px;
  float: left;
}
.supervisor-autocomplete .error i {
  margin: -3px 5px 0px 6px;
  float: left;
}
.supervisor-autocomplete .error span {
  margin: 0px 0px 0px 5px;
  display: flex;
  font-weight: 350;
}

.supervisor-autocomplete .error span a{
  flex: 1;
}

.row .col.cost-center {
  padding: 0px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #616161;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #616161;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #616161;
  font-weight: 300;
}
input[type="text"]:focus {
  border-bottom: 4px solid #000000;
}
.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(11px) scale(1);
  transform: translateY(11px) scale(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

#clear {
  float: right;
  position: relative;
  top: 12px;
  left: -6px;
  transition: opacity 0.2s linear;
  cursor: pointer;
}
</style>
