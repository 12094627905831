/* eslint-disable */
<template>
    
</template>

<script>
import { authProvider } from "../../plugins/auth"

export default {
  mounted() {
    const processLogin = () => {
      // console.log("user is successfully logged in");
      // Actions you want to perform when the user is successfully authenticated.
      this.$router
        .push("/home")
        .catch((err) => console.log("error is -->", err));
    };
    authProvider.handlePromiseRedirectCallback(processLogin);
  },
  methods: {
  },
};
</script>

<style>

</style>