<template>
  <div class="app">
    <NavBar v-if="!$route.meta.hideHeader"/>
    <main class="main">
      <router-view :key="$route.fullPath" />
    </main>
    <FooterComponent v-if="!$route.meta.hideFooter"/>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterComponent from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterComponent,
  },
};
</script>

<style>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
}

.footer {
  background-color: #ffffff;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
