<template>
  <div class="home">
    <OrgChart />
  </div>
</template>

<script>
import OrgChart from "@/components/OrgChart.vue";

export default {
  name: "HomeView",
  components: {
    OrgChart,
  },
  methods: {},
  mounted() {},
  computed: {
  },
};
</script>
<style>
.home{
  padding: 30px;
}
</style>
