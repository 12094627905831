<template>
  <div class="col input-field s4 supervisor-textbox ">
    <label
      >{{ fieldName }}
      <i
        class="material-icons dp48 tooltipped tiny blue-text"
        data-position="right"
        :data-tooltip="instructions"
        >info</i
      >
    </label>
    <br /><br />
    <div>
      <input
        v-model="answer[`${formFieldKey}`]"
        type="text"
        class="supervisor-org"
        maxlength="60"
        data-length="60"
        :placeholder="placeholder"
        id="input_text"
        @blur="validateField"
        @input="changeClear"
        required
      />
      <i class="material-icons txt-clear" @click="clearErrorMsg" id="clear" :style="{opacity: filled ? 1 : 0}"
        >clear</i
      >
    </div>
    <div class="error" v-if="inlineError">
      <i class="material-icons dp48">error_outline</i>
      <span>
        {{ this.inlineError }}
      </span>
    </div>
    <div class="error" v-else-if="answer[`${formFieldKey}`]"></div>
    <div class="error" v-else-if="inlineErrorRequired">
      <i class="material-icons dp48">error_outline</i>
      <span> Please fill out the required field </span>
    </div>
  </div>
</template>
<script scoped>
export default {
  name: "InputText",
  props: {
    answer: Object,
    formFieldKey: String,
    fieldName: String,
    placeholder: String,
    instructions: String,
    inlineErrorRequired: Boolean,
  },
  data() {
    return {
      inlineError: "",
      filled: false
    };
  },
  mounted() {
    $("input#input_text").characterCounter();
    $(".tooltipped").tooltip();
  },
  methods: {
    validateField() {
      this.inlineError = "";
      //Validate whether the field matches the required pattern for org name
      if (
        this.answer[`${this.formFieldKey}`].includes("|") ||
        this.answer[`${this.formFieldKey}`].includes(",") ||
        this.answer[`${this.formFieldKey}`].includes("&")
      ) {
        this.inlineError = `Please select an org name that complies with the naming convention`;
        this.$emit("validateSubmit", ["textbox", false])
      }
      else {
        this.$emit("validateSubmit", ["textbox", true])
      }
    },
    //Happens if you click the clear button 
    clearErrorMsg() {
      this.answer[`${this.formFieldKey}`] = "";
      this.filled = false
    },
    //Changes whether or not the clear button appears 
    changeClear() {
      if(this.answer[`${this.formFieldKey}`] != "") {
        this.filled = true
      }
      else {
        this.filled = false
      }
    }
  },
};
</script>

<style>
.supervisor-textbox .col.s4 {
  margin-left: inherit;
  margin-right: 90px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.supervisor-textbox input {
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  color: #424242;
  margin: 5px 0px 0px 0px;
}
.supervisor-textbox label {
  font-size: 19px;
  font-weight: 400;
  color: #424242;
}
.supervisor-textbox .error {
  color: #d52b1e;
  text-align: left;
  margin: 0px 0 0 0px;
  float: left;
}
.supervisor-textbox .error i {
  margin: -4px 5px 0px 0px;
  float: left;
}
.supervisor-textbox .error span {
  margin: 0px 0px 0px 5px;
  display: flex;
  font-weight: 350;
}

.row .col.cost-center {
  padding: 0px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #616161;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #616161;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #616161;
  font-weight: 300;
}
input[type="text"]:focus {
  border-bottom: 4px solid #000000;
}
.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(11px) scale(1);
  transform: translateY(11px) scale(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.txt-clear {
  float: right;
  position: relative;
  top: -44px !important;
  left:9px !important;
  transition: opacity 0.2s linear;
  cursor: pointer;
}
</style>
