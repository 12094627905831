import displayError from "./errorHandler";
import axios from "axios";
import loader from "./loader";
import store from "../store/index.js"

axios.defaults.headers.get["Pragma"] = "no-cache";
axios.defaults.headers.get["Cache-Control"] = "no-cache, no-store";
export default {
  methods: {
    httpPost: async (url, payload) => {
      loader.showLoading();
      return await axios({
        method: "post",
        url: `${store.getters.getHostName}${url}`,
        responseType: "json",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("userData")).token
        },
        data: payload,
      })
        .then((response) => {
          loader.hideLoading();
          if (response.data.status == 200) {
            return { result: true, msg: response.data };
          } else if (response.data.status == 2000) {
            return { result: false, msg: response.data.msg };
          } else if (response.data.status == 2001) {
            return response.data;
          } else {
            return { result: false, msg: response.data.msg };
          }
        })
        .catch((error) => {
          loader.hideLoading();
          console.log(error);
          displayError("Error occured while processing your request.Please refresh your page or close the browser and reopen...");
        });
    },
  },
};
