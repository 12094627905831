/*eslint-disable */
<template>
  <div class="input-field col s4">
    <label>
      Effective Date <span style="font-size: 10px">(YYYY-MM-DD)</span>
      <i
        style="z-index: 50"
        class="material-icons dp48 tooltipped tiny blue-text"
        data-position="right"
        :data-tooltip="instructions"
        >info</i
      >
    </label>
    <br /><br />
    <input
      id="supervisor-datepicker"
      v-model="answer[`${formFieldKey}`]"
      type="text"
      class="datepicker datepickercolumn date-placeholder"
      @change="onChangeFunc($event.target.value)"
    />
    <i id="date-icon" class="material-icons dp48 icon icon-calender">grid_on</i>
    <div v-if="inlineError" class="datepicker-error error">
      <i class="material-icons dp48">error_outline</i>
      <span>{{ inlineError }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    answer: {
      type: Object,
      default: () => ({}),
    },
    formFieldKey: {
      type: String,
      default: "",
    },
    fieldName: String,
    instructions: String,
  },
  data() {
    return {
      inlineError: "",
    };
  },
  created() {},
  updated() {},
  mounted() {
    //Trigger the datepicker element and set some of the options
    this.initBindings();
    $(document).ready(function () {
      $("#supervisor-datepicker").click(function () {
        $(".datepicker").datepicker({
          format: "yyyy-mm-dd",
          minDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          defaultDate: new Date(),
          showClearBtn: true,
          maxDate: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            31
          ),
        });
      });
    });
  },
  methods: {
    //Validation for whether the date field is filled in and sending the correct response to the final object
    onChangeFunc(value) {
      this.inlineError = "";
      if (!value) {
        this.inlineError =
          "Please select a valid date or leave it blank for the default to today's date";
      }
      this.$emit("validateSubmit", ["datepicker", true]);
      this.answer[`${this.formFieldKey}`] = value.toString();
    },
    initBindings() {
      $("#date-icon").click(function () {
        $("#supervisor-datepicker").click();
      });
    },
  },
};
</script>

<style scoped>
.datepickercolumn {
  width: 95% !important;
}
.icon-calender {
  cursor: pointer;
  float: right;
  margin: 11px 0px 0px 0px;
  color: #263f6a;
}
.datepicker .datepicker-calendar .datepicker-controls .select-month input {
  width: 90px !important;
}

.error {
  color: #d52b1e;
  text-align: left;
  margin: 0px 0 0 0px;
  float: left;
}
.error i {
  margin: -3px 5px 0px 6px;
  float: left;
}
.datepicker-error span {
  margin: 0px 0px 0px 5px !important;
  display: flex;
  font-weight: 350;
}
.error span {
  margin: 10px 0px 0px 5px;
  display: flex;
  font-weight: 350;
}
</style>
