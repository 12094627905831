/*eslint-disable*/

import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: sessionStorage.getItem("userData") ?
      JSON.parse(sessionStorage.getItem("userData")) : "",
      hostname: process.env.VUE_APP_API_HOSTNAME,
      // hostname: "http://localhost:8080/Dev",
      // hostname: "http://localhost:8080/QA",
      isSupervisor: null,
      supervisoryOrg: "",
      routeParamOrg:""
  },
  getters: {
    getUserData: state => {
      return state.userData;
    },
    getHostName: (state) => {
      return state.hostname;
    },
    getSupervisor: (state) => {
      return state.isSupervisor
    },
    getSupervisoryOrg: (state) => {
      return state.supervisoryOrg
    },
    getRouteParamOrg: (state) => {
      return state.routeParamOrg
    }
  },
  mutations: {
    addUserData: (state, userData) => {
      state.userData = userData
    },
    addSupervisor: (state, isSupervisor) => {
      state.isSupervisor = isSupervisor
    },
    addSupervisoryOrg: (state, supervisoryOrg) => {
      state.supervisoryOrg = supervisoryOrg
    },
    addRouteParamOrg: (state, supervisoryOrg) => {
      state.routeParamOrg = supervisoryOrg
    }
  },
  actions: {
    addUserData: (context, userData) => {
      context.commit("addUserData", userData)
    },
    addSupervisor: (context, isSupervisor) => {
      context.commit("addSupervisor", isSupervisor);
    },
    addSupervisoryOrg: (context, supervisoryOrg) => {
      context.commit("addSupervisoryOrg", supervisoryOrg)
    },
    addRouteParamOrg: (context, supervisoryOrg) => {
      context.commit("addRouteParamOrg", supervisoryOrg)
    }
  },
  modules: {
  }
})