/*eslint-disable */
<template>
  <div class="supervisoryorg-wrapper">
    <div class="supervisoryorg-header">
      <h1>Inactivate a Supervisory Organization</h1>
      <p>{{ getUserTitle() }}</p>
    </div>
    <div class="supervisoryorg-body">
      <div class="">
          <div class="">
            <h5 class="inactive-text">
               <b>{{this.userData.Org_Name }}</b> will be inactivated as of today. Please click SUBMIT to confirm. 
            </h5>
          </div>
          <div class="helpful">
            <h6>Helpful Links</h6>
            <hr />
            <ul>
              <li>
                > Get Helpful Information within LillyNow specific to this application:
                <a
                  href="https://now.lilly.com/page/global-making-changes-to-your-supervisory-organization"
                  target="_blank"
                  >Making Changes to Your Supervisory Organization</a
                >
              </li>
              <li>
                > Watch the Inactivate Supervisory Organization Video Explaining This Step:
                <a
                  href="https://1759891.mediaspace.kaltura.com/media/Inactivate%20Supervisory%20Organization/1_6xy0uwjy"
                  target="_blank"
                  >Inactivate Supervisory Organization</a
                >
              </li>
              <li>
                > View the My Supervisory Organization Job Aid for Overall Help:
                <a
                  href="https://collab.lilly.com/sites/LP/Emply_Global/WD_Supervisory_Org.pdf"
                  target="_blank"
                  >My Supervisory Organization</a
                >
              </li>
            </ul>
          </div>
          <div class="action-button">
            <a
              class="waves-effect waves-light btn action-goback"
              @click="$router.push('/home')"
              ><i class="material-icons left">arrow_back</i>Go back</a
            >
            <a
              class="waves-effect waves-light btn action-submit"
              @click="onSubmit()"
              ><i class="material-icons left">play_arrow</i>Submit</a
            >
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import httpPost from "../../mixins/httpPost";

export default {
  name: "DeleteOrg",
  components: {
  },
  mixins: [httpPost],
  data() {
    //Get current Date for effective date 
    let objectDate = new Date();
    let day = objectDate.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    let month = objectDate.getMonth();
    month = month + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let year = objectDate.getFullYear();
    return {
      userData: {},
      deleteOrg: {
        workdayId: "",
        effectiveDate: `${year}-${month}-${day}`,
      },
    };
  },
  methods: {
    showMessage(message, error = false) {
      // eslint-disable-next-line no-undef
      M.toast({ html: message, classes: error ? "red" : "green", displayLength: 7000 });
    },
    onSubmit() {
      const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
      //Make object to pass to inactivate org API
        let data = {
            workdayId: this.deleteOrg.workdayId,
            effectiveDate: this.deleteOrg.effectiveDate,
            managerId: loggedInUser.uid
        };
        // console.log("delete org data:::", data);
        //HTTP Post call for inactivate Org API
        this.httpPost("/inactivatesupervisoryorg", data, false).then((response) => {
          // console.log("form submitter response-->", response);
          if(!response) {
            this.showMessage(
              "Error occured while deleting supervisory org",
              true
            );
          }
          if (response.result !== true) {
            this.showMessage(
              "Error occured while deleting supervisory org",
              true
            );
          } else {
            setTimeout(() => {
              this.$router.push("/home");
            }, 2000);
            this.showMessage("Supervisory organization deleted successfully!");
          }
        });
    },
    //Get Title to Display on Page 
    getUserTitle() {
      const name_title =
        this.userData.Mgr_Name + " - " + this.userData.business_Title;
      return name_title;
    },
  },
  created() {
    const currentUser =  this.$store.getters.getRouteParamOrg;
    if(!currentUser) {
      this.$router.push("/home");
      return
    }
    // console.log('currentUser:::',currentUser);
    this.userData = currentUser; //org code
    if (this.userData.WD_WID) {
        this.deleteOrg.workdayId = this.userData.WD_WID
    }
    var t1 = new Date()
    var t2 = new Date(this.userData.Avail_Date)
    // console.log(this.userData.Avail_Date.substring(0, this.userData.Avail_Date.indexOf("T")))
    if (t1.getTime() < t2.getTime()) {
      this.deleteOrg.effectiveDate = this.userData.Avail_Date.substring(0, this.userData.Avail_Date.indexOf("T"))
    }
  },
};
</script>

<style scoped>

.supervisoryorg-wrapper {
  background: #ffffff;
  padding: 23px 25px 50px 25px;
  width: 93%;
  margin: 37px auto;
  box-shadow: #bdbdbd 0px 0px 5px;
  border-radius: 1px;
  display: block;
  color: #424242;
}
.supervisoryorg-header {
  display: flow-root;
  border-bottom: 1px solid #bdbdbd;
}
.supervisoryorg-header h1 {
  font-size: 26px;
  margin: 0px;
  float: left;
  padding-bottom: 12px;
}
.supervisoryorg-header p {
  font-size: 17px;
  margin: 3px 0px 0px 0px;
  float: right;
  font-weight: 500;
}
.supervisoryorg-body {
  padding: 10px 0px;
}
.action-button {
  margin-top: 100px;
  
}
.action-button .action-goback {
  margin: 0px 25px 0px 0px;
  background: #e4e4e4;
  color: #424242;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 19px;
  letter-spacing: 0ch;
  padding: 3px 15px;
  border-radius: 2px;
  height: 44px;
}
.action-button .action-submit {
  margin: 0px 0px 0px 0px;
  background: #263f6a;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 19px;
  letter-spacing: 0ch;
  padding: 3px 15px;
  border-radius: 2px;
  height: 44px;
}
.inactive-text{
  margin-top: 40px;
  font-size: 17px;
}
.helpful {
  background: #e4e6ec;
  margin-top: 50px;
  margin-left: -0.8%;
  text-align: left;
  padding: 10px 40px 5px 10px;
  line-height: 24px;
  color: #273f6a;
  font-weight: 400;
  font-size: 12px;
  border-radius: 3px;
  display: inline-block;
}
.helpful a {
  color: #263f6a;
  font-weight: bold;
}
</style>
