import displayError from "./errorHandler";
import axios from "axios"
import loader from "./loader"
import store from "../store/index.js"

export default {
    methods: {
        httpGet: async (url) => {
            // console.log(`${store.getters.getHostName}${url}`)
            loader.showLoading();
            return await axios({
                method: "get",
                url: `${store.getters.getHostName}${url}`,
                responseType: "json",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                    Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("userData")).token
                }
            })
            .then((response) => {
                loader.hideLoading();
                // console.log("Got Data from HttpGet", response.data)
                if (response.data.status == 200) {
                    return response.data
                } else if (response.data.status == 2000) {
                    displayError("Error occured while processing your request. Please refresh your page or close the browser and reopen...")
                } else if (response.data.status == 2001) {
                    return response.data
                } else {
                    displayError("Error occured while processing your request. Please refresh your page or close the browser and reopen...")
                }
            })
            .catch((error) => {
                loader.hideLoading()
                console.log("exception", error)
                displayError("Error occured while processing your request. Please refresh your page or close the browser and reopen...")
            })
        }
    }
}
