<template>

  <!-- Dropdown Structure -->
  <a class='dropdown-trigger btn action-drpdown' href='#' :data-target=id>Action</a>

  <ul v-if="class" :id=id class="dropdown-content action-drpdowns" :class=class>
    <li id='select-option-create' @click="this.navigateToOrgCreateUpdate('create')"><a>Create Supervisory Org</a></li>
    <li id='select-option-change' @click="this.navigateToOrgCreateUpdate('change')"><a>Change Supervisory Org</a></li>
    <li id='select-option-inactivate' v-if="this.personData.WD_WID" @click="this.navigateToOrgCreateUpdate('delete')"><a>Inactivate Supervisory Org</a></li>
  </ul>

  <!-- Action Dropdown Structure for Other Nodes -->
  <ul v-else :id=id class="dropdown-content action-drpdowns">
    <li id='select-option-create' @click="this.navigateToOrgCreateUpdate('create')"><a>Create Supervisory Org</a></li>
    <li id='select-option-change' @click="this.navigateToOrgCreateUpdate('change')"><a>Change Supervisory Org</a></li>
    <li id='select-option-inactivate' v-if="this.personData.WD_WID" @click="this.navigateToOrgCreateUpdate('delete')"><a>Inactivate Supervisory Org</a></li>
  </ul>


</template>

<script>
import store from "@/store/index.js";

export default {
  name: 'action-btn',
  props: {
    personData: Object,
    id: String,
    class: String
  },
  mounted() {
    $('.dropdown-trigger').dropdown()
    $('.modal').modal();
    $('li[id^="select-option"]').on('touchend', function (e) {
     e.stopPropagation();
  }); 
  },
  updated: function () {
    $('.dropdown-trigger').dropdown()
  },
  methods: {
    navigateToOrgCreateUpdate(method){
      // console.log("PERSON DATA",this.personData)
      
      // add data to vuex
      store.dispatch("addRouteParamOrg", this.personData);
      // route to create/Update
      this.$router.push({name: method, params: {person: this.personData.Org_Code}})
    },
  }
  
}
</script>

<style>
.dropdown-content{
   width: max-content !important;
   height:auto !important;
}
select {
  width: 100px;
}
.action-drpdowns{
  border-radius: 4px;
  border: 1px solid #D0D0D0;
  
}
.action-drpdowns li>a{
  color: #424242;
  font-size: 14px;
  font-weight: 400;
  border-bottom: 1px solid #D0D0D0;
}
.action-drpdowns li>a:hover{
  background: #DEE2E8;
} 

</style>