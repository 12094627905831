<template>
  <div>
    <h1 style="text-align: left">View My Supervisory Organization as of</h1>

    <DatePickerHome
      @chooseDate="updateDate($event)"
      :defaultDate="this.defaultDate"
    />
    <a
      href="https://now.lilly.com/page/global-making-changes-to-your-supervisory-organization"
      target="_blank"
      class="waves-effect waves-light btn btn-needhelphome"
      style="display: initial; float: left;"
      >Need Help?</a
    >
    <p class="home-links">
      <a
        href="https://1759891.mediaspace.kaltura.com/media/My%20Supervisory%20Organization/1_sap1cts7"
        target="_blank"
        >Sup Org Video</a
      >
      |
      <a
        href="https://collab.lilly.com/sites/LP/Emply_Global/WD_Supervisory_Org.pdf"
        target="_blank"
        >Sup Org Job Aid</a
      >
    </p>
  </div>
  <hr />
  <div class="chartWrapper">
    <!-- Security message if user is not a supervisor -->
    <h1 v-if="this.$store.getters.getSupervisor == false">
      Your security profile does not allow access to this application. Only
      managers and supervisors may use this tool to create, change, and
      inactivate organizations.
    </h1>
    <h1 v-else-if="!this.$store.getters.getSupervisoryOrg">
      <!-- Please wait for the organization chart to load... -->
    </h1>
    <organization-chart
      v-else
      :datasource="this.$store.getters.getSupervisoryOrg"
    >
      <template v-slot="{ nodeData }">
        <div class="cardContainer">
          <div class="cardNav">
            <p :data-letters="nodeData.Mgr_Name && nodeData.Mgr_Name[0]"></p>
            <Action
              v-if="nodeData.last_node_class"
              :class="nodeData.last_node_class"
              :id="nodeData.Org_Code"
              :personData="nodeData"
            />
            <Action v-else :id="nodeData.Org_Code" :personData="nodeData" />
          </div>
          <div class="cardNameHolder">
            <h3>
              {{
                nodeData.Mgr_Name &&
                nodeData.Mgr_Name.substring(0, 27) +
                  (nodeData.Mgr_Name && nodeData.Mgr_Name.length > 27
                    ? ".."
                    : "")
              }}
            </h3>
            <span>{{ nodeData.business_Title }}</span>
          </div>
          <hr />
          <p class="margintop10 marginbottom10">{{ nodeData.Org_Name }}</p>
          <div>
            <img
              class="network-icon"
              src="../assets/image/network-icon.svg"
              alt="network-icon"
              style="cursor: pointer;"
              @click="openOrg()"
            />&nbsp;&nbsp;
            <!-- <button  class="btn modal-trigger">Modal</button> -->
            <p style="padding-right: 10px" class="network-text">
              {{ nodeData.Org_Count }}
            </p>
            &nbsp;&nbsp;
            <span>
              <img
                class="users-icon modal-trigger"
                style="cursor: pointer;"
                src="../assets/image/users-icon.svg"
                alt="users-icon"
                @click="testMethod('open', nodeData.Org_Code + '1', nodeData)"
                :data-target="nodeData.Org_Code + '1'"
              />&nbsp;&nbsp;
              <p class="user-count-text">{{ nodeData.Worker_Count }}</p>
            </span>
          </div>
          <div :id="nodeData.Org_Code + '1'" class="modal">
            <div class="modal-content">
              <h5 style="color: #263f6a;padding-bottom: 5px; border-bottom: 2px solid #4f4f4f;">Employees/Open Positions - {{ nodeData.Mgr_Name }}</h5>
              <h6 v-if="managers.length > 0"><b>Recent updates to move employees will refresh hourly. Only employees and open positions for employees will appear. </b></h6>
              <br><br>
              <ul v-if="managers.length > 0" style="list-style-type: none;padding: 0;border: 1px solid #ddd;">
                <li style="color: #4f4f4f;font-weight: 600;text-align: left;padding: 8px 16px;border-bottom: 1px solid #ddd;cursor: grab;" v-for="(manager, index) in managers" :key="index">
                  {{ manager.Position }}
                </li>
              </ul>
              <h6 v-else style="color: #4f4f4f;font-weight: 600;">You currently have no employees or open positions assigned to your organization. Recent updates to move employees will refresh hourly. Only employees and open positions for employees will appear.</h6>
            </div>
            <div class="modal-footer">
              <a
                @click="testMethod('close', nodeData.Org_Code)"
                class="modal-close waves-effect waves-blue btn-flat close-button"
                >Close</a
              >
            </div>
          </div>

          <div id="modal1" class="modal">
    <div class="modal-content">
      <h4>Modal Header</h4>
      <p>A bunch of text</p>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close waves-effect waves-green btn-flat">Agree</a>
    </div>
  </div>

        </div>
      </template>
    </organization-chart>
  </div>
</template>

<script>
import OrganizationChart from "vue3-organization-chart";
import "vue3-organization-chart/dist/orgchart.css";
import Action from "@/components/Action.vue";
import DatePickerHome from "@/components/Reusable/datepickerHome.vue";
import loader from "../mixins/loader";
import store from "@/store/index.js";
import axios from "axios";
import displayError from "../mixins/errorHandler";
import httpGet from "@/mixins/httpGet";

export default {
  components: {
    OrganizationChart,
    Action,
    DatePickerHome,
  },
  mixins: [httpGet],
  data() {
    //Determine the current date
    let objectDate = new Date();
    let day = objectDate.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    let month = objectDate.getMonth();
    month = month + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let year = objectDate.getFullYear();
    return {
      ds: this.$store.getters.getSupervisoryOrg,
      asOfDate: "",
      defaultDate: `${year}-${month}-${day}`,
      managers: [],
    };
  },
  updated() {
    var outerContent = $(".chartWrapper");
    var innerContent = $(".chartWrapper > div");
    outerContent.scrollLeft((innerContent.width() - outerContent.width()) / 2);
  },
  created() {},
  mounted() {
    // console.log(this.$store.getters.getSupervisor);
    // console.log(this.$store.getters.getSupervisoryOrg);
    $(".datepicker").datepicker();
  },
  methods: {
    openOrg(){
      if(window.location.host.includes('dev') || window.location.host.includes('qa')) {
        window.open('https://wd5-impl.workday.com/lilly1/d/task/2998$2673.htmld', "_blank");
      } else {
        window.open('https://wd5.myworkday.com/lilly/d/task/2998$2673.htmld', "_blank");
      }
    },
    testMethod(method, code, nodeData) {
      // const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
      // console.log(loggedInUser);
      //HTTP Call for getting the workers of a particular person
      if(method == 'open') {
        loader.showLoading();
        let asOfDate = this.asOfDate;
        if (asOfDate == ""){
          asOfDate = this.defaultDate
        }
        this.httpGet(`/getworkers_datewise/${nodeData.Manager_ID}/${asOfDate}/${nodeData.Org_Code}`, false).then(
          (result) => {
            // console.log("request worker data--->", result);
            this.managers = result.data.Report_Entry;
            if(this.managers && this.managers.length > 0) {
              this.managers = this.managers.filter((manager) => manager.Manager.includes(nodeData.Manager_ID))
            }
            $("#" + code).modal();
            $("#" + code).modal(method);

            loader.hideLoading();
  
          }
        );
      } else {
        $("#" + code).modal();
        $("#" + code).modal(method);
      }
    },
    async updateDate(data) {
      const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
      let token = this.$store.getters.getUserData.token;
      let result = await this.httpGetuserAccess(
        `/supervisoryorgs/${loggedInUser.uid}/${data}`,
        true,
        token
      );
      //Decide whether the user is or is not a supervisor based on this if statement and load data into store as needed
      if (result.data.Report_Entry.length === 0) {
        // console.log("Not Supervisor ");
        sessionStorage.setItem("isSupervisor", false);
        store.dispatch("addSupervisor", false);
      } else {
        // console.log("Supervisor");
        // console.log(result.data.Report_Entry);
        sessionStorage.setItem("isSupervisor", true);
        store.dispatch("addSupervisor", true);
        let supervisoryOrg = result.data.Report_Entry;
        let treeData = this.list_to_tree(supervisoryOrg);
        // console.log("tree data:::", treeData);
        store.dispatch("addSupervisoryOrg", treeData[0]);
        loader.hideLoading();
      }
      this.asOfDate = data;
      // console.log("asOfDate", this.asOfDate);
    },
    //Axios Call for Getting the Supervisory Orgs
    async httpGetuserAccess(url, silent, token) {
      return axios({
        method: "get",
        maxBodyLength: Infinity,
        url: `${store.getters.getHostName}${url}`,
        responseType: "json",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          // console.log("Got data", response.data);
          if (response.data.status == 200) {
            return response.data;
          } else if (response.data.status == 2000) {
            displayError(response.data.msg);
          } else if (response.data.status == 2001) {
            return response.data;
          } else {
            displayError(response.data.msg);
          }
        })
        .catch((error) => {
          // console.log("exception", error);
          displayError(error);
        });
    },
    //Function to take get supervisory org array and create array to make organization chart with
    list_to_tree(list) {
      var map = {},
        node,
        roots = [],
        i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].Org_Code] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        //Add a class for the very last node in the org chart
        if (i == list.length - 1) {
          node["last_node_class"] = "chart-row-lastchild";
        }
        if (i !== 0) {
          // if you have dangling branches check that map[node.parentId] exists
          list[map[node.Superior_Code]]["children"].push(node);
        }
        //Add a class for the first node if the supervisor only has one direct report to fix edge case action button issue
        if (list.length == 2 && i == 0) {
          node["last_node_class"] = "chart-row-lastchild";
          roots.push(node);
        } else {
          roots.push(node);
        }
      }
      return roots;
    },
  },
};
</script>

<style>
.row {
  display: flex;
  justify-content: start;
}
.cardContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}
.cardNav {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
}
.cardNameHolder {
  padding: 5px;
}
.node {
  background-color: #f2f2f2;
}

.chartWrapper {
  padding-top: 20px;
  overflow-x: scroll;
  width: 100%;
}
.chartOrgchart {
  border: 0px dashed #d0d0d0;
}
.chartOrgchartContainer {
  border: 0px;
  overflow-x: hidden;
  min-width: 100%;
}

.table {
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  border-collapse: separate;
}
.chartNode {
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin: 0 10px 2px 1px;
  max-width: 100px;
  min-width: 21rem !important;
  min-height: 13rem !important;
  padding: 10px 10px 13px 10px;
  border: 1px solid #bfbfbf;
  text-align: center;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border-radius: 0.35rem;
}
.chartNode:hover {
  box-shadow: 0 0 5px;
  cursor: default;
  z-index: 20;
}
.chartTitle {
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.25rem;
  overflow: hidden;
  white-space: nowrap;
  background: #2d2d2d;
  color: white;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.chartContent {
  box-sizing: border-box;
  width: 100%;
  height: 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  border: #151515;
  text-align: center;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background: white;
  color: black;
  overflow: hidden;
  white-space: nowrap;
}
.chartLines {
  height: 1.25rem;
}
.chartDownLine {
  background: #d0d0d0;
  margin-left: auto;
  margin-right: auto;
  height: 2.25rem;
  width: 0.05rem;
  float: none;
}
.chartTopLine {
  border-top-color: #d0d0d0;
  border-top-style: solid;
  border-top-width: 0.05rem;
  height: 2.25rem;
}
.chartRightLine {
  border-right-color: #d0d0d0;
  border-right-style: solid;
  border-right-width: 0.05rem;
  height: 2.25rem;
}
.chartLeftLine {
  border-left-color: #d0d0d0;
  border-left-style: solid;
  border-left-width: 0.05rem;
  height: 2.25rem;
}

.cardNameHolder h3 {
  font-size: 18px;
  color: #4f4f4f;
  font-weight: 500;
  padding: 10px 0px;
  margin: 0px;
}
.cardNameHolder span {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 400;
}
.chartNode select::-ms-expand {
  display: none;
}

.chartNode select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.chartNode select:hover {
  -webkit-appearance: none;
  -moz-appearance: none;
}
.chartNode select:hover::-ms-expand {
  display: block;
}
.cardContainer p {
  font-size: 13px;
  color: #4f4f4f;
  font-weight: 400;
  text-align: left;
  padding-bottom: 5px;
}
.chartOrgchart .chartNode {
  background: #ffffff;
}
.cardContainer hr {
  background-color: #ddd;
  height: 1px;
  border: 0px;
}
.org-name {
  margin-bottom: 10px;
}
.margintop10 {
  margin-top: 10px;
}
.marginbottom10 {
  margin-bottom: 10px;
}
.chartNode img.network-icon,
.chartNode img.users-icon {
  float: left;
}
.chartNode .network-text,
.chartNode .user-count-text {
  float: left;
  margin-left: 5px;
}
[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 3.5em;
  height: 3.5em;
  line-height: 3.5em;
  text-align: center;
  border-radius: 50%;
  background: #dadfe6;
  vertical-align: middle;
  margin-right: 1em;
  color: #263f6a;
  font-weight: bold;
}
.btn.action-drpdown {
  background: #263f6a !important;
  padding: 0px 28px;
  color: #ffffff;
  border: 0px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  display: block !important;
  height: 2.4rem;
  text-transform: capitalize !important;
  font-weight: 400;
  font-size: 16px;
}
ul.chart-row-lastchild {
  left: 90px !important;
}

.close-button {
  background: #263f6a !important;
  padding: 0px 28px;
  color: #ffffff;
  border: 0px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  height: 2.4rem;
  text-transform: capitalize !important;
  font-weight: 400;
  font-size: 16px;
}


</style>
