<template>
  <div>
    <nav class="navbar">
      <div class="navbar-left">
         <img class="logo" style="margin-top:4px;" src="../assets/image/lillyRed.png" alt="Logo">
        <span class="username">My Supervisory Organization</span>
      </div>
      <div class="navbar-right">
        <span class="name">Hi, {{getEmpDetails}}</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  methods: {},
  mounted() {},
  computed: {
    getEmpDetails() {
      // console.log("user is ", this.$store.getters.getUserData.name);
      // console.log("token for debug only is ", "Bearer " + this.$store.getters.getUserData.token);
      return this.$store.getters.getUserData.name || "user";
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #fff;
  border-bottom:3px #D0D0D0 solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  padding: 0 20px;
}
.navbar-left {
  display: flex;
  align-items: center;
}
.username {
  font-size: 18px;
  font-weight: bold;
}
.navbar-right {
  display: flex;
  align-items: center;
}
.name {
  font-size: 14px;
  font-weight: bold;
  margin-right: auto;
}
.logo {
  height: 30px;
  margin-right: 10px;
}
.navbar .navbar-left span{
font-size: 26px;
color:#585858;
}
.navbar .navbar-right span{
  font-size: 18px;
  font-weight: 500;
  color:#585858;
}

</style>
