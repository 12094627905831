let loadCounter = 0;

const showLoading = notShow => {
    if (!notShow) {
        loadCounter++;
        $(".loading").show();
    }
    // console.log('showloading', loadCounter);

}


const hideLoading = notHide => {
 
    if (!notHide) {
        loadCounter--;
        if (loadCounter <= 0) {
            // loadCounter = 0;
            setTimeout(() => {
                $(".loading").hide();
            }, 3000);
        }
    }
    // console.log('hideloading', loadCounter);

};

module.exports = {
    showLoading, 
    hideLoading,
    loadCounter
}