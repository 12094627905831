/*eslint-disable */
<template>
  
  <div class="supervisoryorg-wrapper">
    <div class="supervisoryorg-header">
      <h1>Create a Supervisory Organization</h1>
      <p>{{ getUserTitle() }}</p>
    </div>
    
    <div class="supervisoryorg-body">
      <div class="row">
        <form class="col s12" ref="form">
          <div class="row">
            <Datepicker
              :answer="createOrg"
              formFieldKey="effectiveDate"
              fieldName="Effective Date (YYYY-MM-DD)"
              instructions="Enter your desired effective date. You may use any date this month or any date one month in the future"
              @validateSubmit="updateValidation($event)"
              @change="OnchangeEffectiveDate($event.target.value)"
            />
            <Dropdown
              v-if="isLoaded"
              :answer="createOrg"
              formFieldKey="positionId"
              :optionData="managers"
              :keyValueData="managersKeyValue"
              :inlineErrorRequired="errors.manager"
              placeholder="Select a manager and position for the new organization"
              supOrgFunction="create"
              @validateSubmit="updateValidation($event)"
              instructions="You will be able to select an employee within your organization that is not already a manager. You will see in the selection who 'is already a manager' but not be able to select them. Recent updates to move employees will refresh hourly. Only employees and open positions for employees will appear."
              @change="OnchangeManagerID($event.target.value)"
            />
          </div>
          <div class="row">
            <Textbox
              :answer="createOrg"
              formFieldKey="organizationName"
              fieldName="Organization Name"
              placeholder="Enter your desired organization name"
              instructions="Org names are limited to 60 characters. Commas, ampersand, pipes, and double pipes are not allowed but other alphanumeric values are allowed. Do not enter the manager's name in this field as it will be included by default in Workday."
              :inlineErrorRequired="errors.organization"
              @validateSubmit="updateValidation($event)"
            />

            <Autocomplete
              v-if="isLoaded"
              :answer="createOrg"
              formFieldKey="costCenterCode"
              fieldName="Cost Center"
              :optionData="cost_centers"
              className="CostCenter"
              placeholder="Select a cost center for the new organization"
              :keyValueData="costCenterKeyValue"
              instructions="The cost center shown is the cost center for the manager's organization"
              :inlineError="errors.costCenter"
              :defaultVal="createOrg2"
              @validateSubmit="updateValidation($event)"
            />
            
          </div>
          <div class="row">
            <Autocomplete
              v-if="isLoaded"
              :answer="createOrg"
              formFieldKey="locationId"
              fieldName="Default Location"
              :optionData="locations"
              className="Location"
              placeholder="Select a Location for the new organization"
              :keyValueData="locationsKeyValue"
              instructions="If the new org should be based outside your location, 
              begin typing the Country 2-letter code, followed by the 
              city name. e.g., US: Indianapolis"
              :inlineError="errors.location"
              :defaultVal="createOrg2"
              @validateSubmit="updateValidation($event)"
            />
          </div>
          <div class="helpful">
            <h6>Helpful Links</h6>
            <hr />
            <ul>
              <li>
                > Get Helpful Information within LillyNow specific to this application:
                <a
                  href="https://now.lilly.com/page/global-making-changes-to-your-supervisory-organization"
                  target="_blank"
                  >Making Changes to Your Supervisory Organization</a
                >
              </li>
              <li>
                > Watch the Create Supervisory Organization Video Explaining This Step:
                <a
                  href="https://1759891.mediaspace.kaltura.com/media/Create%20Supervisory%20Organization/1_2eayvirh"
                  target="_blank"
                  >Create Supervisory Organization</a
                >
              </li>
              <li>
                > View the My Supervisory Organization Job Aid for Overall Help:
                <a
                  href="https://collab.lilly.com/sites/LP/Emply_Global/WD_Supervisory_Org.pdf"
                  target="_blank"
                  >My Supervisory Organization</a
                >
              </li>
            </ul>
          </div>
          <div class="action-button">
            <a
              class="waves-effect waves-light btn action-goback"
              @click="$router.push('/home')"
              ><i class="material-icons left">arrow_back</i>Go back</a
            >
            <a
              class="waves-effect waves-light btn action-submit"
              @click="onSubmit()"
              ><i class="material-icons left">play_arrow</i>Submit</a
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import textbox from "../../components/Reusable/textbox.vue";
import dropdown from "../../components/Reusable/dropdown.vue";
import autocomplete from "../../components/Reusable/autocomplete.vue";
import httpGet from "../../mixins/httpGet";
import httpPost from "../../mixins/httpPost";
import datePicker from "../../components/Reusable/datepicker.vue";
import loader from "../../mixins/loader";


export default {
  name: "CreateOrg",
  components: {
    Textbox: textbox,
    Dropdown: dropdown,
    Autocomplete: autocomplete,
    Datepicker: datePicker,
  },
  mixins: [httpGet, httpPost],
  data() {
    //Get Current Date for Default Effective Date
    let objectDate = new Date();
    let day = objectDate.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    let month = objectDate.getMonth();
    month = month + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let year = objectDate.getFullYear();

    return {
      userData: {},
      createOrg: {
        //Stores information to be passed to the Create Org API
        organizationName: "",
        effectiveDate: `${year}-${month}-${day}`, //Default Effective Date is Today
        positionId: "",
        locationId: "",
        costCenterCode: "",
        superiorOrgId: "",
        managerId: "",
      },
      createOrg2: {}, //Stores the Default Values for the Autocompletes
      managers: [], //Stores the potential managers that could be used for the Create Org Position
      cost_centers: {}, //Stores the potential cost centers that can be chosen in Object Form for Materialize Autocomplete Compatibility
      locations: {}, //Stores the potential locations that can be chosen in Object Form for Materialize Autocomplete Compatibility
      isLoaded: false, //Boolean value to assure all API calls are complete prior to Create Form loading
      managersKeyValue: {}, //Object that maps the manager name to whether or not they are already a manager
      costCenterKeyValue: {}, //Object that maps the cost Center name to the Cost Center Code
      locationsKeyValue: {}, //Object that maps the location name to the Location ID
      errors: {
        //Object stores errors for if org name and manager are empty and submit button clicked
        organization: false,
        manager: false,
      },
      currentDate: `${year}-${month}-${day}`, //Current Date for Defaulting Effective Date To
      validation: {}, //Stores whether the form can be submitted based on validation requirements
    };
  },
  mounted() {
    $(".modal").modal();
  },
  methods: {
    //Method to update validation object based on each individual field
    updateValidation(data) {
      this.validation[data[0]] = data[1];
    },
    OnchangeEffectiveDate(value) {
      let effective_date = value;
      let self = this;
      Promise.allSettled([
      this.httpGet(`/getworkers_datewise/${this.userData.Manager_ID}/${effective_date}/${this.userData.Org_Code}`, false),
    ]).then((results) => {
      let [workersResult] = results;
      if (workersResult.status == 'fulfilled') {
        //console.log("request worker data New API --->", workersResult);
        self.managers = workersResult.value.data.Report_Entry;
        let managerKeyVal = {};
        if(self.managers && self.managers.length > 0) {
          self.managers = self.managers.filter((manager)=> manager.Manager.includes(this.userData.Manager_ID));
        }
        //Go through the managers and mark which ones are managers already and which aren't
        self.managers.map((manager, index) => {
          managerKeyVal[manager.Position_ID] = manager.Is_Manager;
          if (manager.Is_Manager == "1") {
            self.managers[index]["Position"] =
            self.managers[index]["Position"] + " (Is already a manager)";
          }
      });
      self.managersKeyValue = managerKeyVal;
      } else {
        // handle error
        this.showMessage(workersResult.reason, true);
      }
      this.isLoaded = true; //Last API has finished loading
      loader.hideLoading()
    });
    },
    OnchangeManagerID(value){
      let selected_position_id = value;
      let manager_data = this.managers.filter((manager) => manager.Position_ID === selected_position_id);
      manager_data.map((manager) => {
        this.createOrg2["locationId"] = manager.Location_Name;
        this.createOrg2["costCenterCode"] = manager.Cost_Center;
    });
    },
    showMessage(message, error = false) {
      // eslint-disable-next-line no-undef
      M.toast({ html: message, classes: error ? "red" : "green", displayLength: 7000 });
    },
    onSubmit() {
      let submitSuccessful = true; //default submission to true
      for (var key in this.errors) {
        //clear all errors
        this.errors[key] = "";
      }
      if (!this.createOrg.organizationName) {
        //If OrgName not there, mark error in this.errors
        this.errors.organization = true;
        submitSuccessful = false;
      }
      if (!this.createOrg.positionId) {
        //If manager not selected, mark error in this.errors
        this.errors.manager = true;
        submitSuccessful = false;
      }
      if (!this.createOrg.effectiveDate) {
        //If effective date empty, default to today's date
        this.createOrg.effectiveDate = this.currentDate;
      }
      if (!this.createOrg.costCenterCode) {
        //if cost center empty, default to current supervisor's cost center
        this.createOrg.costCenterCode =
          this.costCenterKeyValue[this.userData.Org_Cost_Center];
      }
      if (!this.createOrg.locationId) {
        //If location empty, default to current supervisor's location
        this.createOrg.locationId =
          this.locationsKeyValue[this.userData.Loc_Primary];
      }
      if (Object.values(this.validation).includes(false)) {
        //If any validation is false, submission unsucessful
        submitSuccessful = false;
      }
      // console.log(this.createOrg);
      if (!submitSuccessful) {
        // console.log(this.validation);
        this.showMessage("Please fix the errors below before submission", true);
      }
      if (submitSuccessful) {
        // eslint-disable-next-line no-unused-vars
        const loggedInUser = JSON.parse(sessionStorage.getItem("userData"));
        let data = {
          organizationName: this.createOrg.organizationName,
          effectiveDate: this.createOrg.effectiveDate,
          locationId: this.createOrg.locationId,
          superiorOrgId: this.createOrg.superiorOrgId,
          managerId: loggedInUser.uid,
          // managerId: "3017148",
          positionId: this.createOrg.positionId,
          costCenterCode: this.createOrg.costCenterCode,
        };
        // debugger;
        //API Call to create supervisory organization
        this.httpPost("/createsupervisoryorg", data, false).then((response) => {
          // console.log("form submitter response-->", response);
          if (response.result !== true) {
            this.showMessage(
              "Error occured while creating supervisory org",
              true
            );
          } else {
            setTimeout(() => {
              this.$router.push("/home");
            }, 5000);
            this.showMessage("Supervisory organization created successfully!");
          }
        });
      }
    },
    //Get User Data for displaying on create org screen
    getUserTitle() {
      const name_title =
        this.userData.Mgr_Name + " - " + this.userData.business_Title;
      return name_title;
    },
  },
  created() {
    loader.showLoading()

    const currentUser = this.$store.getters.getRouteParamOrg;
    if (!currentUser) {
      this.$router.push("/home");
      return;
    }
    // console.log("currentUser:::", currentUser);
    this.userData = currentUser; //org code

    // Line 332 and 333 commented since Location and Cost center will be auto populated based on selected Manager (Onchange Manager ID)
    /*this.createOrg2["locationId"] = this.userData.Loc_Primary; //set default location for autocomplete
    this.createOrg2["costCenterCode"] = this.userData.Org_Cost_Center; //set default cost center for autocomplete*/
    
    this.createOrg["superiorOrgId"] = this.userData.Org_Code; //set supervisory org code
    let self = this;

    Promise.allSettled([
      this.httpGet("/getlocations", false),
      this.httpGet("/getcostcenters", false),
      this.httpGet(`/getworkers/${this.userData.Manager_ID}/${this.userData.Org_Code}`, false),
      // this.httpGet(`/getworkers_datewise/${this.userData.Manager_ID}/${this.createOrg.effectiveDate}`, false),
    ]).then((results) => {
      let [locationsResult, costcentersResult, workersResult] = results;
      if(locationsResult.status == 'fulfilled') {
        // console.log("request location data--->", locationsResult.value);
        let locations = {};
        let locationsKeyVal = {};
        const locationData = locationsResult.value.data.Report_Entry;
        //Go through and map the location names to their location ID
        locationData.map((loc) => {
          locations[loc.Location_Name] = null;
          locationsKeyVal[loc.Location_Name] = loc.Location_ID;
        });
        self.locations = locations;
        self.locationsKeyValue = locationsKeyVal;
      } else {
        // handle error 
        this.showMessage(locationsResult.reason, true);
      }

      if (costcentersResult.status == 'fulfilled') {
        // console.log("request cost center data--->", costcentersResult.value);
      let cost_centers = {};
      let costCentersKeyVal = {};
      const costCenterData = costcentersResult.value.data.Report_Entry;
      //Go through and map the cost center name to the cost center code
      costCenterData.map((cc) => {
        cost_centers[cc.Code_CC_Name] = null;
        costCentersKeyVal[cc.Code_CC_Name] = cc.code;
      });
      self.cost_centers = cost_centers;
      self.costCenterKeyValue = costCentersKeyVal;
      } else {
        // handle error
        this.showMessage(costcentersResult.reason, true);
      }
      if (workersResult.status == 'fulfilled') {
        // console.log("request worker data--->", workersResult);
      self.managers = workersResult.value.data.Report_Entry;
      let managerKeyVal = {};
      if(self.managers && self.managers.length > 0) {
        self.managers = self.managers.filter((manager)=> manager.Manager.includes(this.userData.Manager_ID));
      }
      //Go through the managers and mark which ones are managers already and which aren't
      self.managers.map((manager, index) => {
          managerKeyVal[manager.Position_ID] = manager.Is_Manager;
          if (manager.Is_Manager == "1") {
            self.managers[index]["Position"] =
            self.managers[index]["Position"] + " (Is already a manager)";
          }
      });
      self.managersKeyValue = managerKeyVal;
      } else {
        // handle error
        this.showMessage(workersResult.reason, true);
      }
      this.isLoaded = true; //Last API has finished loading
      loader.hideLoading()
    });
  },
};
</script>

<style scoped>
select {
  display: block;
  width: 100%;
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 0px 8px;
  color: #424242;
  font-size: 16px;
  font-weight: 300;
}

.supervisoryorg-wrapper {
  background: #ffffff;
  padding: 23px 25px 50px 25px;
  width: 93%;
  margin: 37px auto;
  box-shadow: #bdbdbd 0px 0px 5px;
  border-radius: 1px;
  display: block;
  color: #424242;
}
.supervisoryorg-header {
  display: flow-root;
  border-bottom: 1px solid #bdbdbd;
}
.supervisoryorg-header h1 {
  font-size: 26px;
  margin: 0px;
  float: left;
  padding-bottom: 12px;
}
.supervisoryorg-header p {
  font-size: 17px;
  margin: 3px 0px 0px 0px;
  float: right;
  font-weight: 500;
}
.supervisoryorg-body {
  padding: 10px 0px;
}
.supervisoryorg-body .row .row {
  margin-bottom: 0px;
}
.supervisoryorg-body .row .col.s4 {
  margin-left: inherit;
  margin-right: 90px;
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 111px;
}
.supervisoryorg-body input {
  border: 1px solid #979797;
  border-radius: 5px;
  padding: 0px 0px 0px 10px;
  color: #424242;
  margin: 5px 0px 0px 0px;
}
.supervisoryorg-body label {
  font-size: 19px;
  font-weight: 400;
  color: #424242;
}
.supervisoryorg-body .error {
  color: #d52b1e;
  text-align: left;
  margin: 0px 0 0 0px;
  float: left;
}
.supervisoryorg-body .error i {
  margin: 7px 5px 0px 0px;
  float: left;
}
.supervisoryorg-body .error span {
  margin: 10px 0px 0px 5px;
  display: flex;
  font-weight: 350;
}
.action-button {
  margin-top: 60px;
  width: inherit;
  float: left;
}
.action-button .action-goback {
  margin: 0px 25px 0px 0px;
  background: #e4e4e4;
  color: #424242;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 19px;
  letter-spacing: 0ch;
  padding: 3px 15px;
  border-radius: 2px;
  height: 44px;
}
.action-button .action-submit {
  margin: 0px 0px 0px 0px;
  background: #263f6a;
  color: #ffffff;
  font-weight: 400;
  text-transform: capitalize;
  font-size: 19px;
  letter-spacing: 0ch;
  padding: 3px 15px;
  border-radius: 2px;
  height: 44px;
}
.supervisoryorg-body .date-placeholder {
  width: 90% !important;
}
.icon-calender {
  margin: 15px 0px 0px 0px;
  float: right;
  cursor: pointer;
  font-size: 28px;
  color: #263f6a;
}
.btn-search {
  margin-top: 6px;
  text-align: center;
  height: 45px;
  background: #263f6a;
  color: #ffffff;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid #263f6a;
}
.btn-search i {
  margin-right: 0px;
  margin-top: 5px;
  font-size: 25px;
}
.row .col.cost-center {
  padding: 0px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #616161;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #616161;
  font-weight: 300;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #616161;
  font-weight: 300;
}
input[type="text"]:focus {
  border-bottom: 4px solid #000000;
}
.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(11px) scale(1);
  transform: translateY(11px) scale(1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.helpful {
  float: left;
  background: #e4e6ec;
  margin-left: -0.8%;
  text-align: left;
  padding: 5px 40px 5px 10px;
  line-height: 24px;
  color: #273f6a;
  font-weight: 400;
  font-size: 12px;
  border-radius: 3px;
}
.helpful a {
  color: #263f6a;
  font-weight: bold;
}
</style>
